import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import "./MakeAnAdmin.css";
import AdminDashboard from "../AdminPage/Admin";

const MakeAnAdmin = () => {
  const [users, setUsers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/getAllUser`
        );
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const updateAdminStatus = async (userId) => {
    const isAdmin = selectedStatus[userId];

    if (!isAdmin) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/isAdmin`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId, isAdmin }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === userId ? { ...user, isAdmin } : user
          )
        );
        alert("Admin status updated successfully!");
      } else {
        console.error("Error updating user:", data.message);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleAdminChange = (userId, newStatus) => {
    setSelectedStatus((prevStatus) => ({
      ...prevStatus,
      [userId]: newStatus,
    }));
  };

  const handleSubmit = (userId) => {
    updateAdminStatus(userId);
  };

  return (
    <>
      {/* <Nav /> */}
      <AdminDashboard/>
      <div className="user-container">
        <h1>User List</h1>
        <div className="user-cards">
          {users.map((user) => (
            <div key={user._id} className="user-card">
              <div className="user-info">
                <h3>{user.username}</h3>
                <p>{user.email}</p>
              </div>
              <div className="user-status">
                <select
                  value={selectedStatus[user._id] || user.isAdmin}
                  onChange={(e) => handleAdminChange(user._id, e.target.value)}
                >
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
              </div>

              <button
                className="submit-button"
                onClick={() => handleSubmit(user._id)}
              >
                Submit
              </button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MakeAnAdmin;
