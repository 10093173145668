import React, { useState } from "react";
import "./Signup.css";
import { Link, useNavigate } from "react-router-dom";
import Nav from "../Navpage/Nav";
import Footer from "../footer/Footer";
import ButtonInput from "../buttonInput/ButtonInput";
import { enqueueSnackbar } from "notistack";

const SignupPage = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let debounceTimer;

  const debounce = (func, delay) => {
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const signupHandler = debounce(async () => {
    if (!username || !email.trim() || !password.trim()) {
      console.log("Username, email, or password cannot be empty");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username.trim(),
            email: email.trim(),
            password: password.trim(),
          }),
        }
      );
      const res = await response.json();
      console.log(res);

      if (res?.message) {
        enqueueSnackbar(res?.message, { variant: "error" });
      }

      if (res?.token) {
        enqueueSnackbar("Register Successfully", { variant: "success" });
        navigate("/login");
      } else {
        navigate("/signup");
      }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, 300);

  return (
    <>
      <Nav />
      <div id="main">
        <form className="signup-page-form" onSubmit={handleSubmit}>
          <div id="signUp-card">
            <h1 id="register">Register</h1>

            <input
              className="signUp-input"
              type="text"
              placeholder="Username*"
              name="name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              className="signUp-input"
              type="text"
              placeholder="Email*"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
            />
            <input
              className="signUp-input"
              type="password"
              placeholder="Password*"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value.trim())}
            />

            <br />
            {/* <button
              id="signUp-btn"
              type="submit"
              onClick={signupHandler}
              disabled={
                isLoading ||
                !username.trim() ||
                !email.trim() ||
                !password.trim()
              }
            >
              {isLoading}
            </button> */}

            <ButtonInput
              className="Login-btn"
              title="Sign Up"
              onClick={signupHandler}
              isLoading={isLoading}
              disabled={
                isLoading ||
                !username.trim() ||
                !email.trim() ||
                !password.trim()
              }
            />

            <div className="alreadyAccount">
              <p>
                Already have an account? <Link to="/login">Login</Link>{" "}
              </p>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default SignupPage;
