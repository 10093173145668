import React from 'react';
import { Carousel } from 'react-bootstrap';
import './ImageCrouselHome.css';
import thought1 from '../../Assets/dr. apj-Abdul-kalam-3-2.webp';
import thought2 from '../../Assets/swami-vivekanand-1.webp';
import thought3 from '../../Assets/dayanand-saraswati1.webp';
import thought4 from '../../Assets/banner-2-scaled-1.webp';

const ImageCarouselThought = () => {
  const carouselItems = [
    {
      imgpath: thought1,
      altText: 'First slide',
    },
    {
      imgpath: thought2,
      altText: 'Second slide',
    },
    {
      imgpath: thought3,
      altText: 'Third slide',
    },
    {
      imgpath: thought4,
      altText: 'Fourth slide',
    },
  ];

  return (
    <Carousel className='img-crau'>
      {carouselItems.map((item, index) => (
        <Carousel.Item key={index} className='carousel-item'>
          <img
            className="d-block w-100"
            src={item.imgpath}
            alt={item.altText}
            style={{
              height: '35vh',
              width: '100vw',
              objectFit: 'cover',
            }}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ImageCarouselThought;
