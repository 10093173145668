import React, { useEffect, useState } from "react";
import "./GetAllTypingData.css";
import { enqueueSnackbar } from "notistack"; // For notifications
import Footer from "../footer/Footer";
import AdminDashboard from "../AdminPage/Admin";

const GetAllTypingData = () => {
  const [typingData, setTypingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchTypingData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/typing/getallword`
        );

        const data = await response.json();
        setTypingData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching typing data:", error);
        enqueueSnackbar("Error fetching typing data", { variant: "error" });
        setIsLoading(false);
      }
    };

    fetchTypingData(); // Call the fetch function when the component mounts
  }, []);

  // Function to handle the deletion of a card
  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/typing/deleteword/${id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete typing data");
      }

      enqueueSnackbar("Typing data deleted successfully", {
        variant: "success",
      });

      // Remove the deleted item from the state
      setTypingData((prevData) => prevData.filter((item) => item._id !== id));
    } catch (error) {
      console.error("Error deleting typing data:", error);
      enqueueSnackbar("Error deleting typing data", { variant: "error" });
    }
  };

  return (
    <>
      {/* <Nav /> */}
      <AdminDashboard/>
      <div className="get-all-typing-container">
        <h1>All Typing Data</h1>

        {isLoading ? (
          <p>Loading...</p> 
        ) : typingData.length > 0 ? (
          <div className="typing-cards">
            {typingData.map((item) => (
              <div key={item._id} className="typing-card">
                <h2>{item.chapterName}</h2>
                <p>{item.para}</p>
                <button
                  
                  onClick={() => handleDelete(item._id)} 
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p>No typing data found.</p>
        )}
      </div>
      <Footer />
    </>
  );
};

export default GetAllTypingData;
