import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginState, logout } from '../userInfo/authSlice';
import Cookies from 'js-cookie';

export const useAuth = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        // const token = localStorage.getItem('token'); // or sessionStorage if you prefer
        const token = Cookies.get("myToken")
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                if (decodedToken.exp * 1000 > Date.now()) {
                    dispatch(setLoginState({ isLoggedIn: true, user: decodedToken }));
                } else {
                    dispatch(logout());
                }
            } catch (error) {
                console.error('Invalid token:', error);
                dispatch(logout());
            }
        } else {
            dispatch(logout());
        }
    }, [dispatch]);

    return { isLoggedIn, user };
};
