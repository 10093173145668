import React, { useState } from "react";
import { enqueueSnackbar } from "notistack";
import ButtonInput from "../buttonInput/ButtonInput";
import "./CreateQuiz.css";
import AdminDashboard from "../AdminPage/Admin";

const CreateQuiz = () => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctAnswer, setCorrectAnswer] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const createQuiz = async () => {
    setIsLoading(true);
    const quizData = {
      question,
      options,
      correctAnswer,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/quiz/createQuiz`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(quizData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create quiz");
      }

      const result = await response.json();
      console.log(result);

      enqueueSnackbar("Quiz created successfully!", { variant: "success" });
    } catch (err) {
      setError(err.message);
      enqueueSnackbar("Error: " + err.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  return (
    <>
      <AdminDashboard />
      <div className="quiz-container">
        <h2 className="quiz-title">Create Quiz</h2>

        <div className="form-group">
          <label className="form-label">Question:</label>
          <input
            className="form-input"
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Enter question"
          />
        </div>

        <div className="form-group">
          {options.map((option, index) => (
            <div key={index} className="option-group">
              <label className="form-label">Option {index + 1}:</label>
              <input
                className="form-input"
                type="text"
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                placeholder={`Enter option ${index + 1}`}
              />
            </div>
          ))}
        </div>

        <div className="form-group">
          <label className="form-label">Correct Answer (0-3):</label>
          <input
            className="form-input"
            type="number"
            value={correctAnswer}
            onChange={(e) => setCorrectAnswer(Number(e.target.value))}
            min="0"
            max="3"
            placeholder="Enter correct answer index"
          />
        </div>

        <ButtonInput
          className="submit-btn"
          title="Create Quiz"
          onClick={createQuiz}
          isLoading={isLoading}
          disabled={
            isLoading ||
            !question.trim() ||
            options.some((option) => !option.trim()) ||
            correctAnswer === null
          }
        />

        {error && <p className="error-message">{error}</p>}
      </div>
    </>
  );
};

export default CreateQuiz;
