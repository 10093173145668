import React from "react";

const HumburgerMenu = ({ classNamee, onclick }) => {
  return (
    <svg
      onClick={onclick}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      fill="none"
      viewBox="0 0 18 14"
      className={classNamee}
    >
      <rect width="18" height="2" fill="#ff782d" rx="1"></rect>
      <rect width="12.96" height="2" y="6" fill="#ff782d" rx="1"></rect>
      <rect width="18" height="2" y="12" fill="#ff782d" rx="1"></rect>
    </svg>
  );
};

export default HumburgerMenu;
