import React, { useEffect, useState } from "react";
import "./GetAllCourses.css";
import { enqueueSnackbar } from "notistack"; // For notifications
import AdminDashboard from "../AdminPage/Admin";

const GetAllCourses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/course/getAllCourse`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setCourses(data); // Set the fetched courses data
        console.log("Courses data:", data); // Log the data to the console
        setLoading(false);
      } catch (error) {
        console.error("Error fetching courses:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  const deleteHandler = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/course/deleteById?id=${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the course");
      }

      const data = await response.json();

      enqueueSnackbar("Course deleted successfully!", { variant: "success" });

      // Update the state to remove the deleted course
      setCourses((prevCourses) =>
        prevCourses.filter((course) => course._id !== id)
      );

      console.log("Course deleted successfully:", data);
    } catch (error) {
      enqueueSnackbar("Error deleting the course", { variant: "error" });
      console.error("Error deleting the course:", error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <AdminDashboard />
      <div className="courses-container">
        <h1>All Courses</h1>
        <div className="course-cards">
          {courses.length > 0 ? (
            courses.map((course) => (
              <div className="course-card" key={course._id}>
                <img
                  src={course.courseimg}
                  alt={course.coursename}
                  className="course-image"
                />
                <div className="course-info">
                  <h3>{course.coursename}</h3>
                  <p>
                    <strong>Type:</strong> {course.coursetype}
                  </p>
                  <p>
                    <strong>Duration:</strong> {course.courseduration}
                  </p>
                  <p>
                    <strong>Eligibility:</strong> {course.courseeli}
                  </p>
                  <p>
                    <strong>Description:</strong> {course.coursedes}
                  </p>
                </div>
                <button
                  className="delete-btn"
                  onClick={() => deleteHandler(course._id)}
                >
                  Delete
                </button>
              </div>
            ))
          ) : (
            <p>No courses found.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default GetAllCourses;
