import React, {  useState } from "react";
import "./SplashScreen.css";
import Homepage from "../homepage/Homepage";

const SplashScreen = () => {
  const [splash, setSplash] = useState(false);

  const goToHome = () => {
    setSplash(true);
  };

  if (splash === false) {
    setTimeout(() => {
      goToHome();
    }, 200);
  }

  return (
    <>
      {splash === true ? <Homepage /> : <div className="splash-screen">
        <img
          src="https://media1.tenor.com/m/jgDVuidR3bkAAAAC/cat-spinning.gif"
          alt=""
        />
      </div>}
    </>
  );
};

export default SplashScreen;
