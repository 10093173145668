import React from "react";

function Icon({ size = '2rem', color = '#29166F', className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      viewBox="0 0 24 24"
      height={size}
      width={size}
      className={className}
    >
      <path d="M12.001 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.954 9.954 0 01-5.03-1.355L2.005 22l1.352-4.968A9.953 9.953 0 012.001 12c0-5.523 4.477-10 10-10zM8.593 7.3l-.2.008a.961.961 0 00-.372.1 1.293 1.293 0 00-.294.228c-.12.113-.188.211-.261.306A2.73 2.73 0 006.9 9.62c.002.49.13.967.33 1.413.409.902 1.082 1.857 1.97 2.742.214.213.424.427.65.626a9.448 9.448 0 003.84 2.046l.568.087c.185.01.37-.004.556-.013a1.99 1.99 0 00.833-.231c.166-.088.244-.132.383-.22 0 0 .043-.028.125-.09.135-.1.218-.171.33-.288.083-.086.155-.187.21-.302.078-.163.156-.474.188-.733.024-.198.017-.306.014-.373-.004-.107-.093-.218-.19-.265l-.582-.261s-.87-.379-1.402-.621a.497.497 0 00-.176-.041.482.482 0 00-.378.127c-.005-.002-.072.055-.795.931a.35.35 0 01-.368.13 1.43 1.43 0 01-.191-.066c-.124-.052-.167-.072-.252-.108a6.025 6.025 0 01-1.575-1.003c-.126-.11-.243-.23-.363-.346a6.297 6.297 0 01-1.02-1.268l-.059-.095a.923.923 0 01-.102-.205c-.038-.147.061-.265.061-.265s.243-.266.356-.41c.11-.14.203-.276.263-.373.118-.19.155-.385.093-.536-.28-.684-.57-1.365-.868-2.041-.059-.134-.234-.23-.393-.249-.054-.006-.108-.012-.162-.016a3.385 3.385 0 00-.403.004l.201-.008z"></path>
    </svg>
  );
}

export default Icon;
