// import React from 'react';
// import './ScrollingText.css';

// const ScrollingText = () => {
//   return (
//     <div className="scrolling-container">
//       <div className="scrolling-text">
//         Admissions Open : | B.Com | B.Sc | BCA | MA | MCA | MBA | Diploma | DCA | PGDCA | PG Diploma | B.Lib | M.Lib | Programing | Nursing | Steno | CPCT | E-MBA |

//         Admissions Open : | B.Com | B.Sc | BCA | MA | MCA | MBA | Diploma | DCA | PGDCA | PG Diploma | B.Lib | M.Lib | Programing | Nursing | Steno | CPCT | E-MBA |
//       </div>
      
//     </div>
//   );
// };

// export default ScrollingText;

import React from 'react';
import './ScrollingText.css';

const ScrollingText = () => {
  return (
    <div className="scrolling-container">
      <div className="scrolling-text">
        <span>Admissions Open : | B.Com | B.Sc | BCA | MA | MCA | MBA | Diploma | DCA | PGDCA | PG Diploma | B.Lib | M.Lib | Programing | Nursing | Steno | CPCT | E-MBA |</span>
        
      </div>
    </div>
  );
};

export default ScrollingText;

