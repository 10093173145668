import React, { useState } from "react";
import { useSnackbar } from "notistack";
import Footer from "../../footer/Footer";

import "./CreateCourse.css";
import ButtonInput from "../../buttonInput/ButtonInput";
import AdminDashboard from "../Admin";

const CreateCourse = () => {
  const [courseType, setCourseType] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseDuration, setCourseDuration] = useState("");
  const [eligibility, setEligibility] = useState("");
  const [courseDes, setCourseDes] = useState(""); // Textarea for course description
  const [courseImg, setCourseImg] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const clearFunction = () => {
    setCourseType("");
    setCourseName("");
    setCourseDuration("");
    setEligibility("");
    setCourseDes(""); // Clear course description
    setCourseImg(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("coursetype", courseType);
    formData.append("coursename", courseName);
    formData.append("courseduration", courseDuration);
    formData.append("courseeli", eligibility);
    formData.append("coursedes", courseDes); // Correctly map the course description
    formData.append("courseimg", courseImg);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/course/createCourse`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create course");
      }

      const responseData = await response.json();
      console.log("Response from server:", responseData);

      enqueueSnackbar("Course created successfully!", { variant: "success" });
      clearFunction();
    } catch (error) {
      console.error("Error:", error.message);
      enqueueSnackbar("Error creating course. Please try again.", {
        variant: "error",
      });
    }
  };

  const courseTypeArr = [
    { value: "master", name: "Master Degree" },
    { value: "bachelor", name: "Bachelor Degree" },
    { value: "diploma", name: "Diploma" },
    { value: "master(private)", name: "Master Degree (Private)" },
    { value: "bachelor(private)", name: "Bachelor Degree (Private)" },
    { value: "certification", name: "Certification" },
    { value: "pg diploma", name: "PG Diploma" },
    { value: "master(regular)", name: "Master Degree (Regular)" },
    { value: "bachelor(regular)", name: "Bachelor Degree (Regular)" },
    { value: "other", name: "Others" },
  ];

  return (
    <>
      <AdminDashboard />
      <div className="create-course-wrapper">
        <div className="create-course-card">
          <h1>Create a New Course</h1>
          <form
            onSubmit={handleSubmit}
            className="create-course-form"
            encType="multipart/form-data"
          >
            <label htmlFor="courseType">Course Type</label>
            <select
              id="courseType"
              value={courseType}
              onChange={(e) => setCourseType(e.target.value)}
              required
            >
              <option value="" disabled>
                Select Course Type
              </option>
              {courseTypeArr.map((elem, index) => (
                <option key={index} value={elem.value}>
                  {elem.name}
                </option>
              ))}
            </select>

            <label htmlFor="courseName">Course Name</label>
            <input
              type="text"
              id="courseName"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
              placeholder="e.g. BCA, MBA"
              required
            />

            <label htmlFor="courseDuration">Course Duration</label>
            <input
              type="text"
              id="courseDuration"
              value={courseDuration}
              onChange={(e) => setCourseDuration(e.target.value)}
              placeholder="e.g. 3 years, 2 years"
              required
            />

            <label htmlFor="eligibility">Eligibility</label>
            <input
              type="text"
              id="eligibility"
              value={eligibility}
              onChange={(e) => setEligibility(e.target.value)}
              placeholder="e.g. 12th pass, Graduation"
              required
            />

            <label htmlFor="courseDes">Course Description</label>
            <textarea
              id="courseDes"
              value={courseDes}
              onChange={(e) => setCourseDes(e.target.value)}
              placeholder="Provide a detailed description of the course."
              rows="4"
              required
            />

            <label htmlFor="courseImg">Course Image</label>
            <input
              type="file"
              id="courseImg"
              onChange={(e) => setCourseImg(e.target.files[0])}
              accept="image/*"
              required
            />

            <ButtonInput
              className="submit-btn"
              title="Submit"
              onClick={handleSubmit}
              isLoading={false}
              disabled={false}
            />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreateCourse;
