import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    valuee: [],
    loading: false,
    error: null
}

const courseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        getAllCourseStart: (state) => {
            state.loading = true;
        },
        getAllCourseSuccess: (state, action) => {
            state.valuee = action.payload;
            state.loading = false;
        },
        getAllCourseFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export const { getAllCourseStart, getAllCourseSuccess, getAllCourseFailure } = courseSlice.actions;

export const fetchCourses = () => async (dispatch) => {
    dispatch(getAllCourseStart());
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/course/getAllCourse`);
        const data = await response.json();
        dispatch(getAllCourseSuccess(data));
    } catch (error) {
        dispatch(getAllCourseFailure(error.toString()));
    }
}

export default courseSlice.reducer;
