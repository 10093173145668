import React, { useState } from "react";
import "./AdvertismentDrag&Drop.css";
import CloseIcon from "../../icons/CloseIcon";
import { enqueueSnackbar } from "notistack";
import AdminDashboard from "../AdminPage/Admin";

const AdvertismentDragDrop = () => {
  const [imageSrc, setImageSrc] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const cancleHandler = () => {
    setImageSrc(null);
    setSelectedFile(null);
  };

  const postImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("img", file);

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/ad/adCreate`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const result = await response.json();
      cancleHandler();
      console.log(result);

      enqueueSnackbar("Image uploaded successfully!", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to upload image", { variant: "error" });
    }
  };

  const handleManualUpload = () => {
    if (selectedFile) {
      postImage(selectedFile);
    } else {
      enqueueSnackbar("No file selected", { variant: "warning" });
    }
  };

  return (
    <>
      {/* <Nav /> */}
      <AdminDashboard/>
      <div id="parent-container">
        <div
          className={isDragging ? "drag-drop-area active" : "drag-drop-area"}
        >
          <div
            className="drag-drop-content"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            {imageSrc ? (
              <div className="cancle-btn" onClick={cancleHandler}>
                <CloseIcon />
              </div>
            ) : (
              <></>
            )}
            {imageSrc ? (
              <img
                src={imageSrc}
                alt="Dropped"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <p>
                Drop your image OR{" "}
                <label htmlFor="choose">
                  <span style={{ cursor: "pointer" }}>Choose From Gallery</span>
                </label>
              </p>
            )}

            <input type="file" hidden id="choose" onChange={handleFileSelect} />
          </div>
        </div>
        {selectedFile && (
          <button onClick={handleManualUpload} className="upload-btn">
            Upload
          </button>
        )}
      </div>
    </>
  );
};

export default AdvertismentDragDrop;
