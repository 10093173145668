import { configureStore } from "@reduxjs/toolkit";
import couterReducer from "./counter/counterSlice";
import userReducer from "./userInfo/userInformation";
import courseSlice from "./courses/courseSlice";
import auth from "./userInfo/authSlice"


const store = configureStore({
    reducer: {
        counter: couterReducer,
        currentUser: userReducer,
        course: courseSlice,
        auth: auth
    },

})



export default store;