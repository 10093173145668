import React from "react";
import UnprotectedRoutes from "./Routes/UnprotectedRoutes";
import { SnackbarProvider } from "notistack";

const App = () => {
  return (
    <SnackbarProvider maxSnack={2}>
      <UnprotectedRoutes />
    </SnackbarProvider>
  );
}

export default App;
