import React from 'react'
import Footer from '../footer/Footer'
import Nav from '../Navpage/Nav'
import "./ContectUs.css"
import Iconphone from "../../icons/PhoneIcon"
import Iconemail from "../../icons/EmailIcon"
import Iconlocation from "../../icons/LocationIcon"
import Iconwhatsapp from "../../icons/WhatsappIcon"
import Iconfacebook from "../../icons/FacebookIcon"
import { useSelector } from 'react-redux'


const ContectUs = () => {
  const data = useSelector((elem) => elem.currentUser.user);
  const name = data[0]?.user.username;
  return (
    <>
    <Nav name={name || "USER"}/>
    <div id="contect-page1">
        <div id="contect-map-section">
            <div id="contect-section">
              <div id="contect-section-sub-div">
                <div id="contect-heading">
                <h1 className='heading-data'>Get in Touch with Us Today!</h1>
                <br/>
                <h4 className='subheading-data'>We are here to assist you with any questions or concerns. <br/> Reach out to us through the following contact details:</h4>
                </div>

                <div id="all-contect-details"> 
                  <div className="contect-section-phone-box">
                      <div className="icon-div"><Iconphone/></div>
                      <div className="phone-txt-number">
                        <div className="phone-txt">Phone :</div>
                        <div className="phone-number"> 9752398636 <br/>9685370104 </div>
                      </div>
                  </div>
                  <div className="contect-section-phone-box">
                      <div className="icon-div"><Iconphone/></div>
                      <div className="phone-txt-number">
                        <div className="phone-txt">Telephone :</div>
                        <div className="phone-number"> 0755 4013563</div>
                      </div>
                  </div>
                  <div className="contect-section-phone-box">
                      <div className="icon-div"><Iconemail/></div>
                      <div className="phone-txt-number">
                        <div className="phone-txt">Email :</div>
                        <div className="phone-number"> admission@hitechbhopal.com </div>
                      </div>
                  </div>
                  <div className="contect-section-phone-box">
                      <div className="location-icon-div"><Iconlocation/></div>
                      <div className="phone-txt-number">
                        <div className="phone-txt">Location :</div>
                        <div className="phone-number"> BDA 96 C, Maa Hinglaj Sewa Sansthan, Shivaji Nagar<br></br> Bhopal (MP) 462016</div>
                      </div>
                  </div>
                  <div className="contect-section-phone-box">
                      <div className="icon-div"><Iconwhatsapp/></div>
                      <div className="phone-txt-number">
                        <div className="phone-txt">WhatsApp :</div>
                        <div className="phone-number">9752398636 </div>
                      </div>
                  </div>
                  <div className="contect-section-phone-box">
                      <div className="icon-div"><Iconfacebook/></div>
                      <div className="phone-txt-number">
                        <div className="phone-txt">Facebook :</div>
                        <div className="phone-number">Hitech Group of Institutions</div>
                      </div>
                  </div>

                </div>

              </div>
            </div>
            <div id="About-right-submain" style={{ width: '100%', height: 'auto' }}>
            <iframe
              id="inlineFrameHitech"
              title='map'
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d458.3333389783794!2d77.4221535!3d23.2296649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c42f53c880af7%3A0x2f11fd292161a05d!2sHitech%20Group%20Of%20Institutions!5e0!3m2!1sen!2sin!4v1694956250455!5m2!1sen!2sin"
            ></iframe>
          </div>
        </div>  
    </div>
    <Footer/>
    </>
  )
}

export default ContectUs
