import React, { useEffect, useState } from "react";
import "./GetAllCertifiate.css";
import "../CertificateCard/CertificateCard.css";
import Footer from "../footer/Footer";
import CertificateCard from "../CertificateCard/CertificateCard";
import AdminDashboard from "../AdminPage/Admin";
import { enqueueSnackbar } from "notistack";

const GetAllCertificate = () => {
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/certificate/getAllCartificate`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCertificates(data.reverse());
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredCertificates = certificates.filter((certificate) =>
    Object.values(certificate).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const deleteHandler = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/certificate/deleteCertificate/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the certificate");
      }

      const data = await response.json();

      enqueueSnackbar("Certificate deleted successfully!", {
        variant: "success",
      });

      console.log("Certificate deleted successfully:", data);
    } catch (error) {
      enqueueSnackbar("Error deleting the certificate", { variant: "error" });
      console.error("Error deleting the certificate:", error);
    }
  };

  return (
    <div>
      {/* <Nav /> */}
      <AdminDashboard />
      <div className="certificates-list">
        <h1>All Certificates</h1>
        <input
          type="text"
          placeholder="Search certificates..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        {filteredCertificates.length > 0 ? (
          filteredCertificates.map((certificate) => (
            <CertificateCard
              category_image={certificate.category_image}
              certificateNumber={certificate.certificateNumber}
              course={certificate.course}
              duration={certificate.deration}
              fatherName={certificate.fatherName}
              studentEmail={certificate.studentEmail}
              studentName={certificate.studentName}
              onDelete={() => deleteHandler(certificate._id)}
            />
          ))
        ) : (
          <p>No certificates found.</p>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default GetAllCertificate;
