// Import Swiper React components and Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./ImageSwiper.css"

// Import Autoplay from swiper/modules/autoplay
import { Autoplay } from "swiper/modules";

// Placeholder data
const cardData = [
  {
    icon: "https://aiu.edu.in/images/institution-icon.png", // Replace with your icon/image
    title: "COMMITMENT",
    description: "We show respect, compassion, and humanity for our customers and colleagues."
  },
  {
    icon: "https://aiu.edu.in/images/institution-icon0.png", // Replace with your icon/image
    title: "RESPONSIBILITY",
    description: "We ensure that what comes from the people goes back to the people many times over."
  },
  {
    icon: "https://aiu.edu.in/images/like.png", // Replace with your icon/image
    title: "Research",
    description: "Research is formalized curiosity. It is poking and prying with a purpose."
  },
  {
    icon: "https://aiu.edu.in/images/check-mark.png", // Replace with your icon/image
    title: "QUALITY",
    description: "Continuously upgrade its curricula in response to customer demands and emerging technology."
  },
  {
    icon: "https://aiu.edu.in/images/institution-icon.png", // Replace with your icon/image
    title: "COMMITMENT",
    description: "We show respect, compassion, and humanity for our customers and colleagues."
  },
  {
    icon: "https://aiu.edu.in/images/institution-icon0.png", // Replace with your icon/image
    title: "RESPONSIBILITY",
    description: "We ensure that what comes from the people goes back to the people many times over."
  },
  {
    icon: "https://aiu.edu.in/images/like.png", // Replace with your icon/image
    title: "Research",
    description: "Research is formalized curiosity. It is poking and prying with a purpose."
  },
  {
    icon: "https://aiu.edu.in/images/check-mark.png", // Replace with your icon/image
    title: "QUALITY",
    description: "Continuously upgrade its curricula in response to customer demands and emerging technology."
  },
];

const ImageSwiper = () => {
  return (
    <div className="swiper-container">
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false
        }}
        modules={[Autoplay]}
        breakpoints={{
          1200: {
            slidesPerView: 4,
          },
          900: {
            slidesPerView: 3,
          },
          600: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }}
      >
        {cardData.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="card">
              <div className="card-icon-circle">
                <img className="card-icon" src={item.icon} alt={item.title} />
              </div>
              <h3 className="card-title">{item.title}</h3>
              <p className="card-description">{item.description}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageSwiper;
