import React, { useState } from "react";
import "./GenerateCertificate.css";
import Footer from "../../footer/Footer";
import { enqueueSnackbar } from "notistack";
import ButtonInput from "../../buttonInput/ButtonInput";
import AdminDashboard from "../Admin";

const GenerateCertificate = () => {
  const [studentName, setStudentName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [course, setCourse] = useState("");
  const [duration, setDuration] = useState("");
  const [image, setImage] = useState(null); // State to hold the selected image
  const [isLoading, setIsLoading] = useState(false);
  const [dob, setDob] = useState("");

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Set the selected file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(); // Use FormData for file upload
    formData.append("studentName", studentName);
    formData.append("fatherName", fatherName);
    formData.append("studentEmail", studentEmail);
    formData.append("course", course);
    formData.append("duration", duration);
    formData.append("dateOfBirth", dob);
    if (image) {
      formData.append("category_image", image); // Append the image file
    }
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/certificate`,
        {
          method: "POST",
          body: formData, // Send FormData directly
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate certificate");
      }

      const data = await response.json();
      console.log("Certificate generated:", data);
      setIsLoading(false);
      emptyHandler();
      enqueueSnackbar("Certificate Generated", { variant: "success" });
    } catch (error) {
      console.error("Error generating certificate:", error);
      enqueueSnackbar("Error generating certificate", { variant: "error" });
    }
  };

  const emptyHandler = () => {
    setStudentName("");
    setFatherName("");
    setStudentEmail("");
    setCourse("");
    setDuration("");
    setDob("");
    setImage(null);
  };

  return (
    <>
      <AdminDashboard/>
      <div className="generate-certificate-container">
        <h1>Generate Certificate</h1>
        <form onSubmit={handleSubmit} className="generate-certificate-form">
          <label htmlFor="studentName">Student Name</label>
          <input
            type="text"
            id="studentName"
            value={studentName}
            onChange={(e) => setStudentName(e.target.value)}
            placeholder="Enter Student Name"
            required
          />

          <label htmlFor="studentName">Student Date of Birth</label>
          <input
            type="date"
            id="dob"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            placeholder="Enter Student Date of Birth"
            required
          />

          <label htmlFor="fatherName">Father // Husband Name</label>
          <input
            type="text"
            id="fatherName"
            value={fatherName}
            onChange={(e) => setFatherName(e.target.value)}
            placeholder="Enter Father's Name"
            required
          />

          <label htmlFor="studentEmail">Student Email</label>
          <input
            type="email"
            id="studentEmail"
            value={studentEmail}
            onChange={(e) => setStudentEmail(e.target.value)}
            placeholder="Enter Student Email"
            required
          />

          <label htmlFor="course">Course</label>
          <input
            type="text"
            id="course"
            value={course}
            onChange={(e) => setCourse(e.target.value)}
            placeholder="Enter Course Name"
            required
          />

          <label htmlFor="duration">Duration (in years)</label>
          <input
            type="number"
            id="duration"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            placeholder="Enter Duration in Years"
            required
          />

          <label htmlFor="category_image">Category Image</label>
          <input
            type="file"
            id="category_image"
            onChange={handleImageChange}
            accept="image/*" // Ensure only images can be selected
          />

          <ButtonInput
            isLoading={isLoading}
            title="Generate Certificate"
            className="Login-btn"
          />
        </form>
      </div>
      <Footer />
    </>
  );
};

export default GenerateCertificate;
