import React, { useEffect, useState, useRef } from "react";
import "./TypingPage.css";
import { useNavigate } from "react-router-dom";
import Nav from "../Navpage/Nav";
import Footer from "../footer/Footer";
import StudentQuiz from "../StudentQuiz/StudentQuiz";

const TypingPage = () => {
  const [randomText, setRandomText] = useState(
    `Select your day for Typing`
  );
  const [inputText, setInputText] = useState("");
  const [correctKeysPressed, setCorrectKeysPressed] = useState(0);
  const [totalKeysPressed, setTotalKeysPressed] = useState(0);
  const [totalWords, setTotalWords] = useState(0);
  const [timer, setTimer] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [selectedMinutes, setSelectedMinutes] = useState(1);
  const [wpm, setWpm] = useState(0);
  const [accuracy, setAccuracy] = useState(0);
  const [language, setLanguage] = useState("english");
  const [showResults, setShowResults] = useState(false);
  const [scrollCount, setScrollCount] = useState(0); // State for tracking scroll count

  const wordRefs = useRef([]); // To reference each word span
  const wordsToShow = 5; // Number of lines to display
  const days = Array.from({ length: 10 }, (_, index) => `${index + 1}`);
  console.log(totalWords, wordsToShow);

  const dayClickFetchData = async (day) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/typing/${language}/${day}`
      );
      const data = await res.json();

      if (Array.isArray(data)) {
        const text = data.join(" ");
        setRandomText(text);
        setTotalWords(text.split(" ").length);
        resetTimer();
      } else {
        console.error("Unexpected data format", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const randomClickFetchData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/typing/getrandompara?isType=${language}`
      );
      const data = await res.json();

      setRandomText(data[0].para);
    } catch (error) {
      console.log(error);
    }
  };

  const calculateResults = () => {
    const totalTypedWords = inputText.trim().split(" ").length;
    const accuracyValue = (
      (correctKeysPressed / totalKeysPressed) *
      100
    ).toFixed(2);
    const wpmValue = (totalTypedWords / selectedMinutes).toFixed(2);

    setAccuracy(accuracyValue);
    setWpm(wpmValue);
  };

  useEffect(() => {
    if (timer > 0 && isStarted && !isPaused) {
      const timerId = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timerId);
    } else if (timer === 0 && isStarted) {
      setIsStarted(false);
      setShowResults(true);
    }
  }, [timer, isStarted, isPaused]);

  const handleChange = (e) => {
    calculateResults();
    if (!isStarted || isPaused) return;
    const value = e.target.value;
    setInputText(value);

    const randomWords = randomText.split(" ");
    const inputWords = value.trim().split(" ");

    // Update correct and total keys pressed
    setCorrectKeysPressed(
      inputWords.filter((word, index) => word === randomWords[index]).length
    );
    setTotalKeysPressed(inputWords.length);

    // Scroll the view to the current word only a few times
    const currentWordIndex = inputWords.length - 1;

    // Check if the current word index is within the bounds
    if (
      currentWordIndex < randomWords.length &&
      wordRefs.current[currentWordIndex]
    ) {
      setScrollCount((prevCount) => prevCount + 1); // Increment scroll count
      if (scrollCount < 4) {
        // Scroll up to 4 times
        wordRefs.current[currentWordIndex].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Reset scroll count after scrolling
      if (scrollCount >= 4) {
        setScrollCount(0);
      }
    }
  };

  const startTimer = () => {
    if (isPaused) {
      setIsPaused(false);
    } else {
      setTimer(selectedMinutes * 60);
      setIsStarted(true);
      setShowResults(false); // Hide results when the test starts
      setInputText("");
      setCorrectKeysPressed(0);
      setTotalKeysPressed(0);
    }
  };

  const pauseTimer = () => {
    setIsPaused(true);
  };

  const resetTimer = () => {
    setIsStarted(false);
    setTimer(0);
    setInputText("");
    setCorrectKeysPressed(0);
    setTotalKeysPressed(0);
    setShowResults(false); // Hide results when resetting
  };

  const timeData = [0.2, 1, 2, 3, 4, 5, 10, 15, 20, 30];
  const navigate = useNavigate();

  return (
    <>
      <Nav />
      <div className="typing-page-layout">
        <div className="typing-sidebar">
          {days.map((day, index) => (
            <button
              key={index}
              className="day-button"
              onClick={() => dayClickFetchData(day)}
              disabled={isStarted}
            >
              Lesson {day}
            </button>
          ))}
          <button
            className="day-button"
            onClick={randomClickFetchData}
            disabled={isStarted}
          >
            Random Paragraph
          </button>
        </div>

        <div className="typing-page-body">
          <div className="typing-language-bar">
            <button
              className="typing-login-button"
              onClick={() => navigate("/signup")}
            >
              Login
            </button>
            <div>
              <input
                type="radio"
                id="english"
                name="language"
                value="english"
                checked={language === "english"}
                onChange={(e) => setLanguage(e.target.value)}
                disabled={isStarted}
              />
              <label htmlFor="english">English</label>

              <input
                type="radio"
                id="hindi"
                name="language"
                value="hindi"
                checked={language === "hindi"}
                onChange={(e) => setLanguage(e.target.value)}
                disabled={isStarted}
                style={{ marginLeft: "10px" }}
              />
              <label htmlFor="hindi">Hindi</label>
            </div>
            <h5 className="typing-nav-heading">Switch Typing Test Language</h5>
          </div>

          {/* <div className="typing-word-box">
            {randomText.split(" ").map((word, index) => {
              const inputWords = inputText.trim().split(" ");
              let color = "black"; // Default color for untyped words
              if (inputWords[index] === word) {
                color = "green"; // Correctly typed word
              } else if (inputWords[index] && inputWords[index] !== word) {
                color = "red"; // Incorrectly typed word
              }

              return (
                <span
                  key={index}
                  style={{ color }}
                  ref={(el) => (wordRefs.current[index] = el)} // Set reference for scrolling
                  className="word"
                >
                  {word}
                </span>
              );
            })}
          </div> */}

          <div className="typing-word-box">
            {randomText.split(" ").map((word, index) => {
              const inputWords = inputText.trim().split(" ");
              let color = "black"; // Default color for untyped words

              if (index < inputWords.length) {
                const typedWord = inputWords[index].trim();

                if (typedWord.localeCompare(word) === 0) {
                  color = "green"; // Correct word
                } else if (typedWord !== "") {
                  color = "red"; // Incorrect word
                }
              }

              return (
                <span
                  key={index}
                  style={{ color: color, marginRight: "4px" }} // Add margin to create spacing
                  ref={(el) => (wordRefs.current[index] = el)} // Reference each word
                >
                  {word}
                </span>
              );
            })}
          </div>

          <textarea
            value={inputText}
            onChange={handleChange}
            className="typing-input"
            placeholder="Type here..."
            rows={2}
          ></textarea>
          <div className="typing-controls">
            <div className="timer-container">
              <h5>Time: {timer} seconds</h5>
              <label>
                Select Time:
                <select
                  value={selectedMinutes}
                  onChange={(e) => setSelectedMinutes(e.target.value)}
                  disabled={isStarted}
                >
                  {timeData.map((time, index) => (
                    <option key={index} value={time}>
                      {time} min
                    </option>
                  ))}
                </select>
              </label>
              <button onClick={startTimer} className="typing-button">
                {isStarted ? "Resume" : "Start"}
              </button>
              <button onClick={pauseTimer} className="typing-button">
                Pause
              </button>
              <button onClick={resetTimer} className="typing-button">
                Reset
              </button>
            </div>
          </div>
          {showResults && (
            <div className="result">
              <h5>Your Results:</h5>
              <p>WPM: {wpm}</p>
              <p>Accuracy: {accuracy}%</p>
            </div>
          )}
        </div>
        <StudentQuiz />
      </div>
      <Footer />
    </>
  );
};

export default TypingPage;