import React from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import HomeIcon from "../../icons/HomeIcon";

const Sidebar = ({ isDrawer, setIsDrawer }) => {
  const data = [
    {
      name: "Home",
      navigate: "/home",
      icon: <HomeIcon />,
    },
    {
      name: "Course",
      navigate: "/course",
      icon: <HomeIcon />,
    },
    {
      name: "Contect us",
      navigate: "/contectus",
      icon: <HomeIcon />,
    },
    {
      name: "About us",
      navigate: "/aboutus",
      icon: <HomeIcon />,
    },
    {
      name: "Get Student",
      navigate: "/getstudent",
      icon: <HomeIcon />,
    },
    {
      name: "News-Web",
      navigate: "/home",
      icon: <HomeIcon />,
    },
    {
      name: "Login",
      navigate: "/login",
      icon: <HomeIcon />,
    },
    {
      name: "Register",
      navigate: "/signup",
      icon: <HomeIcon />,
    },
    {
      name: "Admin",
      navigate: "/admindashboard",
      icon: <HomeIcon />,
    },
  ];
  return (
    <div className="overlay-box" onClick={setIsDrawer}>
      <div className={isDrawer}>
        <div className="menu-box">
          {data.map((elem, index) => {
            return (
              <div className="menu-icon-box" key={index}>
                {elem.icon}
                <Link to={elem.navigate} key={index}>
                  {elem.name}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
