import React, { useState } from "react";
import "./Loginpage.css";
import { Link, useNavigate } from "react-router-dom";
import Nav from "../Navpage/Nav";
import Footer from "../footer/Footer";
import ButtonInput from "../buttonInput/ButtonInput";
import Cookies from "js-cookie";
import { enqueueSnackbar } from "notistack";

const Loginpage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  let debounceTimer;

  const debounce = (func, delay) => {
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const signinHandler = debounce(async () => {
    if (!email.trim() || !password.trim()) {
      enqueueSnackbar("Please fill all the fields", { variant: "error" });
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email.trim(),
          password: password.trim(),
        }),
      });
      const res = await response.json();
      console.log(res);
      if (res?.token) {
        Cookies.set("myToken", res.token, { expires: 7 });
        enqueueSnackbar("Login Succesfully", { variant: "success" });
        navigate("/");
      } else {
        enqueueSnackbar("Wrong Crediantial", { variant: "error" });
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }, 300);

  return (
    <>
      <Nav />
      <div id="main">
        <form className="login-page-form" onSubmit={handleSubmit}>
          <div id="Login-card">
            <h1 id="login">Login</h1>

            <input
              className="login-input"
              type="text"
              placeholder="Email*"
              name="email"
              onChange={(e) => setEmail(e.target.value.trim())}
            />

            <input
              className="login-input"
              type="password"
              placeholder="Password*"
              name="password"
              onChange={(e) => setPassword(e.target.value.trim())}
            />

            <br />

            <ButtonInput
              className="Login-btn"
              title="Login"
              onClick={signinHandler}
              isLoading={isLoading}
              disabled={isLoading || !email.trim() || !password.trim()}
            />

            <div className="alreadyAccount">
              <p>
                Don't have an account?<Link to="/signup">Register</Link>
              </p>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Loginpage;
