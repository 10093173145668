import React, { useState } from "react";
import "./GetStudentPage.css";
import Nav from "../Navpage/Nav";
import Footer from "../footer/Footer";
import { useSelector } from "react-redux";
import CertificateCard from "../CertificateCard/CertificateCard";

const GetStudentPage = () => {
  const [certificateNumber, setCertificateNumber] = useState("");
  const [certificateData, setCertificateData] = useState(null);
  const [error, setError] = useState(null);

  const data = useSelector((elem) => elem.currentUser.user);
  const name = data[0]?.user.username;

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/certificate/getcertificate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ certificateIdentifier: certificateNumber }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setCertificateData(data);
        setError(null);
      } else {
        setError(data.message || "Something went wrong");
        setCertificateData(null);
      }
    } catch (err) {
      setError("Failed to fetch certificate data");
      setCertificateData(null);
    }
  };

  return (
    <>
      <Nav name={name || "USER"} />
      <div className="get-student-page">
        <h2>Search Certificate</h2>
        <div className="search-bar">
          <input
            type="text"
            value={certificateNumber}
            onChange={(e) => setCertificateNumber(e.target.value)}
            placeholder="Enter Certificate Number and Date of Year"
          />

          <button onClick={handleSearch}>Search</button>
        </div>

        {error && <div className="error">{error}</div>}

        {certificateData && (
          <CertificateCard
            category_image={certificateData.category_image}
            certificateNumber={certificateData.certificateNumber}
            course={certificateData.course}
            dateOfBirth={certificateData.dateOfBirth}
            duration={certificateData.duration}
            fatherName={certificateData.fatherName}
            studentEmail={certificateData.studentEmail}
            studentName={certificateData.studentName}
          />
        )}
      </div>
      <Footer />
    </>
  );
};

export default GetStudentPage;
