import React, { useEffect } from "react";
import "./Homepage.css";
import Nav from "../Navpage/Nav";
import Footer from "../footer/Footer";
import Cookies from "js-cookie";
import certificationpng from "../../Assets/certification-right-course-name.png";
import CertificationIcon from "../../icons/CertificationIcon";
import Logofeedback from "../../Assets/feedbackLogo";
import Posterstudentlogo from "../../Assets/Homeposterstudent.png";
import StudentGroupLogo from "../../Assets/Student-grout.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchCourses } from "../../redux/courses/courseSlice";
import typingpngimage from "../../Assets/typing.png";
import { decodeTokenn } from "../../redux/Actions/userActions/decodeToken";
import { adduser } from "../../redux/userInfo/userInformation";
import ImageCarousel from "../Carousel/Homepagecarousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useAuth } from "../../redux/hook/useAuth";
import ScrollingText from "../ScrollingTextpage/ScrollingText";
import ImageSwiper from "../ImageSwiper/ImageSwiper";
import ImageCarouselThought from "../Carousel/ImageCrouselHome";
import partner1 from '../../Assets/partner1.png'
import partner2 from '../../Assets/partner2.png'
import partner3 from '../../Assets/partner3.png'
import partner4 from '../../Assets/partner4.png'
import partner5 from '../../Assets/partner5.png'
import partner6 from '../../Assets/partner6.png'
import partner7 from '../../Assets/partner7.png'
import partner8 from '../../Assets/partner8.png'
import partner9 from '../../Assets/partner9.png'
import NotificationPage from "../Notification/NotificationPage";


const Homepage = () => {
  const userdata = Cookies.get("myToken");
  const courseDispatch = useDispatch();

  useEffect(() => {
    courseDispatch(fetchCourses());
  }, [courseDispatch]);

  const data = decodeTokenn(userdata);

  const dispatch = useDispatch();
  dispatch(adduser(data));

  const achivmentCardData = [
    {
      headingdata: "15K+",
      subheadingdata: "Active Students",
    },
    {
      headingdata: "150+",
      subheadingdata: "Total Courses",
    },
    {
      headingdata: "10+",
      subheadingdata: "Instructor",
    },
    {
      headingdata: "100%",
      subheadingdata: "Satisfaction Rate",
    },
  ];

  const courseCardData = [
    {
      name: "PGDCA",
      type: "Diploma",
      duration: "1 Year",
      eligibility: "Graduation",
      image: "https://htips.in/wp-content/uploads/2020/07/PGDCA.jpg",
    },
    {
      name: "DCA",
      type: "Diploma",
      duration: "1 Year",
      eligibility: "12th Pass",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4oBdQewEZXWc0fWP35of2_jASZEc8iuDSeXdfbyJKWjjQDz1qV7BE3npa22FxnO1d8NI&usqp=CAU",
    },
    {
      name: "Shorthand",
      type: "Certification",
      duration: "6 Month",
      eligibility: "12th Pass",
      image:
        "https://www.wikihow.com/images/thumb/0/02/Write-Shorthand-Step-15-Version-3.jpg/v4-460px-Write-Shorthand-Step-15-Version-3.jpg",
    },
    {
      name: "Programing",
      type: "Certification",
      duration: "9 Months",
      eligibility: "12th + Graduation",
      image:
        "https://png.pngtree.com/background/20230525/original/pngtree-female-programmer-in-front-of-computer-screens-picture-image_2734159.jpg",
    },
    {
      name: "MBA",
      type: "Degree",
      duration: "2 Year",
      eligibility: "Graduation",
      image: "https://www.meridean.org/images/uk/mba-in-uk.png",
    },
    {
      name: "B.COM",
      type: "Degree",
      duration: "3 Year",
      eligibility: "12th Pass",
      image:
        "https://www.cheggindia.com/wp-content/uploads/2023/08/bcom-full-form.png",
    },
  ];


  const imageData = [
    { id: 1, src: partner1, alt: 'Business Partner 1' },
    { id: 2, src: partner2, alt: 'Business Partner 2' },
    { id: 3, src: partner3, alt: 'Business Partner 3' },
    { id: 4, src: partner4, alt: 'Business Partner 4' },
    { id: 5, src: partner5, alt: 'Business Partner 5' },
    { id: 6, src: partner6, alt: 'Business Partner 6' },
    { id: 7, src: partner7, alt: 'Business Partner 7' },
    { id: 8, src: partner8, alt: 'Business Partner 8' },
    { id: 9, src: partner9, alt: 'Business Partner 9' },
 
  ];


  const { isLoggedIn, user } = useAuth();
  console.log(isLoggedIn, user);

  return (
    <>
      <Nav />

      <ScrollingText />

      <div id="homepage1">
        <ImageCarousel />
      </div>
      <NotificationPage/>

      <div id="homepage2">
        <div id="masterdiv">
          <div id="typingPoster">
            <div id="typinglogosection">
              <img
                className="typingMaster"
                src={typingpngimage}
                alt="typingMasterLogo"
              />
            </div>
            <div id="typingPosterDescription">
              <h2>Master Your Typing Skills</h2>
              <h4>
                Join our typing master and practice to achieve speed and
                accuracy
              </h4>
              <Link className="typingPoster-btn" to="/typing">
                Start Typing
              </Link>
            </div>
          </div>
          <div id="topcourse">
            <div id="courseheading">
              <div id="courseheadings">
                <h2 className="courseheading1">Featured Course</h2>
                <h4 className="subheadingpage2">Explore our Popular Courses</h4>
              </div>
              <div id="btn">
                <Link to="/course" className="course--btn">
                  All Courses
                </Link>
              </div>
            </div>

            <div id="coursecardparent">
              {courseCardData.map((elem) => {
                return (
                  <div className="mycard" key={elem.id}>
                    <div className="cimg">
                      <img src={elem.image} alt="card-img" />
                    </div>
                    <div className="cdetail">
                      <div className="course-name">{elem.name}</div>
                      <div className="course-type">
                        Course Type: {elem.type}
                      </div>
                      <div className="course-duration">
                        Course Duration: {elem.duration}
                      </div>
                      <div className="course-eligibility">
                        Course Eligibility: {elem.eligibility}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* poster 1 */}
          <div id="posterHome">
            <div id="studentlogosection">
              <img src={Posterstudentlogo} alt="studentpng" />
              <h3>Let's Start With Hitech Group of Institutions</h3>
            </div>
            <div id="buttonsection">
              <Link className="postercontect-btn" to="/contectus">
                Contect Now
              </Link>
              <Link className="posterviewcourses-btn" to="/course">
                Explore Courses
              </Link>
            </div>
          </div>
          {/* poster ending */}

          {/* achivment section start */}
          <div id="achivment-section">
            {achivmentCardData.map((e, i) => {
              return (
                <div id="achivment-active-student-card" key={i}>
                  <h2 className="achivment-heading">{e.headingdata}</h2>
                  <h4 className="achivment-subheading">{e.subheadingdata}</h4>
                </div>
              );
            })}
          </div>
          {/* achivment section ending */}

          {/* Certification section start */}
          <div id="certification-section">
            <div id="certification-image-section">
              <CertificationIcon className={"certificationicon"} />
            </div>
            <div id="certification-description-section">
              <div id="certification-description">
                <div id="certification-description-heading">
                  Grow Us Your Skill With <br />
                  Hitech Institutions
                </div>
                <div id="certification-description-content">
                  <div id="certification-content-subheading">
                    Certification Offerings: Discover our comprehensive
                    certification programs across various fields to enhance your
                    skills and expertise.
                  </div>
                  <div id="certification-courses-name-section">
                    <div className="certification-courses-name-section">
                      <div className="certification-png">
                        <img src={certificationpng} alt="right-img" />
                      </div>
                      <div className="certification-png">
                        <p className="certification-course-name">
                          PGDCA Certification
                        </p>
                      </div>
                    </div>
                    <div className="certification-courses-name-section">
                      <div className="certification-png">
                        <img src={certificationpng} alt="right-img" />
                      </div>
                      <div className="certification-png">
                        <p className="certification-course-name">
                          DCA Certification
                        </p>
                      </div>
                    </div>
                    <div className="certification-courses-name-section">
                      <div className="certification-png">
                        <img src={certificationpng} alt="right-img" />
                      </div>
                      <div className="certification-png">
                        <p className="certification-course-name">
                          B.Com Certification
                        </p>
                      </div>
                    </div>
                    <div className="certification-courses-name-section">
                      <div className="certification-png">
                        <img src={certificationpng} alt="right-img" />
                      </div>
                      <div className="certification-png">
                        <p className="certification-course-name">
                          MBA Certification
                        </p>
                      </div>
                    </div>
                    <div className="certification-courses-name-section">
                      <div className="certification-png">
                        <img src={certificationpng} alt="right-img" />
                      </div>
                      <div className="certification-png">
                        <p className="certification-course-name">
                          MOM Short Hend Steno Certification
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="certification-description-button">
                  <Link className="certification-btn" to="/course">
                    Explore Course
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Certification section end */}
          <ImageSwiper />

          {/* Homepage poster 2*/}
          <div id="posterHome2">
            <div id="studentlogosection">
              <img
                className="StudentGroup"
                src={StudentGroupLogo}
                alt="studentpng"
              />
            </div>
            <div id="Posterdescription">
              <h2>Find A Hitech Institute Near You</h2>
              <h4>Connecting You to Quality Education Across Our Branches</h4>
              <Link className="poster2-btn" to="/allbranches">
                View All Branches
              </Link>
            </div>
          </div>
          {/* poster ending 2*/}

          {/* Feedback section start */}
          <div id="student-feedback-section">
            <div id="studen-feedback-heading">
              <h1 className="student-feedback-heading">Student Feedback</h1>
              <h4 className="student-subheading">
                What Students Say About Hitech institute
              </h4>
            </div>
            <div id="feedback-card-section">
              <div className="student-feedback-card">
                <div className="feedbacks">
                  <Logofeedback />
                  <h3 className="student-feedback">
                    I am very happy with the institute. The professors are
                    knowledgeable and very approachable. The overall environment
                    is very conducive to learning
                  </h3>
                </div>
                <div className="feedbacker-name">
                  <h2>Rahul Verma</h2>
                  <h4 className="feedbacker-course">MBA</h4>
                </div>
              </div>

              <div className="student-feedback-card">
                <div className="feedbacks">
                  <Logofeedback />
                  <h3 className="student-feedback">
                    The faculty here is amazing and always ready to help. The
                    learning experience has been wonderful, and the institute
                    provides a positive atmosphere
                  </h3>
                </div>
                <div className="feedbacker-name">
                  <h2>Priya Singh</h2>
                  <h4 className="feedbacker-course">B.A.</h4>
                </div>
              </div>

              <div className="student-feedback-card">
                <div className="feedbacks">
                  <Logofeedback />
                  <h3 className="student-feedback">
                    I am really impressed with the institute. The teachers are
                    very dedicated and make learning enjoyable. They are always
                    willing to help with any doubts
                  </h3>
                </div>
                <div className="feedbacker-name">
                  <h2>Amit Kumar</h2>
                  <h4 className="feedbacker-course">B.Tech.</h4>
                </div>
              </div>

              <div className="student-feedback-card">
                <div className="feedbacks">
                  <Logofeedback />
                  <h3 className="student-feedback">
                    I am really impressed with the institute. The teachers are
                    very dedicated and make learning enjoyable. They are always
                    willing to help with any doubts
                  </h3>
                </div>
                <div className="feedbacker-name">
                  <h2>Amit Kumar</h2>
                  <h4 className="feedbacker-course">B.Tech.</h4>
                </div>
              </div>

              <div className="student-feedback-card">
                <div className="feedbacks">
                  <Logofeedback />
                  <h3 className="student-feedback">
                    The institute has a very good academic environment. The
                    faculty members are very supportive and ensure that we
                    understand the subjects well. My experience has been very
                    positive
                  </h3>
                </div>
                <div className="feedbacker-name">
                  <h2>Kavita Reddy</h2>
                  <h4 className="feedbacker-course">M.Sc.</h4>
                </div>
              </div>
            </div>
          </div>

          {/* Feedback section compleate */}

          {/* typing poster */}



          <div id="Thoughts-Carousel">
            <ImageCarouselThought />
          </div>

          <div id="business-partners">

            {imageData.map((image) => (
              <img key={image.id} src={image.src} alt={image.alt} />
            ))}

          </div>



        </div>
      </div>
      <Footer />
    </>
  );
};

export default Homepage;
