import React from 'react'
import { Carousel } from 'react-bootstrap';
import image1 from '../../Assets/hitech-img.png'
import image2 from '../../Assets/aboutimage1.jpg'
import image3 from '../../Assets/aboutimage2.jpg'
import image4 from '../../Assets/aboutimage3.jpg'
const Aboutpagecarousel = () => {
  return  (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image1}
          alt="First slide"
          style={{ height: "100vh", width: "100vw", objectFit: "cover" }}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image2}
          alt="Second slide"
          style={{ height: "100vh", width: "100vw", objectFit: "cover" }}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image3}
          alt="Third slide"
          style={{ height: "100vh", width: "100vw", objectFit: "cover" }}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image4}
          alt="Third slide"
          style={{ height: "100vh", width: "100vw", objectFit: "cover" }}
        />
      </Carousel.Item>
      {/* Add more Carousel.Item as needed */}
    </Carousel>
  );
}

export default Aboutpagecarousel
