import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: [],
    adPoster: [],
}
const userSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        adduser: (state, action) => {
            state.user.push(action.payload);
        },
        delteUser: (state, action) => {
            state.user = [];
        },
        adUser: (state, action) => {
            state.adPoster.push(action.payload);
        }
    }
})

export const { adduser, delteUser, adUser } = userSlice.actions;
export default userSlice.reducer;


