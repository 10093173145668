import React from "react";
import Nav from "../Navpage/Nav";
import Footer from "../footer/Footer";
import "./ErrorPage.css";
import ErrorImg from "../../Assets/Frame.png";

const ErrorPage = () => {
  return (
    <>
      <Nav />
      <div className="error-page">
        <div className="error-page-inner">
        <h1>Error 404</h1>
        <img src={ErrorImg} alt="" />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ErrorPage;
