import React from "react";
import "./CertificateCard.css";

const CertificateCard = ({
  certificateNumber,
  studentName,
  studentEmail,
  fatherName,
  course,
  duration,
  dateOfBirth,
  category_image,
  onDelete, 
}) => {
  return (
    <div className="certificate-card">
      <div className="certificate-card-image">
        <img
          src={
            category_image ||
            "https://w7.pngwing.com/pngs/205/731/png-transparent-default-avatar-thumbnail.png"
          }
          alt="Certificate"
        />
      </div>
      <div className="certificate-card-content">
        <h2>Certificate Details</h2>
        <p>
          <strong>Certificate Number:</strong> {certificateNumber}
        </p>
        <p>
          <strong>Student Name:</strong> {studentName}
        </p>
        <p>
          <strong>Student Email:</strong> {studentEmail}
        </p>
        <p>
          <strong>Father's Name:</strong> {fatherName}
        </p>
        <p>
          <strong>Course:</strong> {course}
        </p>
        <p>
          <strong>Date of Birth:</strong> {dateOfBirth}
        </p>
        <p>
          <strong>Duration:</strong> {duration} years
        </p>
      </div>
      <button className="delete-btn" onClick={onDelete}>
        Delete
      </button>
      
    </div>
  );
};

export default CertificateCard;
