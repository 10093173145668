import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import "./DeleteAd.css"; // Import CSS file for styling
import { enqueueSnackbar } from "notistack";
import AdminDashboard from "../AdminPage/Admin";


const DeleteAd = () => {
  const [adData, setAdData] = useState([]);
  useEffect(() => {
    const dataFetching = async () => {
      const data = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/ad/getAd`
      );
      const res = await data.json();
      setAdData(res);
    };
    dataFetching();
  }, []);


  const deleteAd = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/ad/ads/${id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete ad");
      }

      setAdData((prevAds) => prevAds.filter((ad) => ad._id !== id));

      enqueueSnackbar("Ad deleted successfully!", { variant: "success" });
    } catch (error) {
      console.error("Error deleting ad:", error);
      enqueueSnackbar("Failed to delete ad", { variant: "error" });
    }
  };

  return (
    <>
      {/* <Nav /> */}
      <AdminDashboard/>
      <div className="ad-container">
        {adData.map((ad, index) => (
          <div key={ad._id} className="ad-card">
            <img src={ad.imgpath} alt={`Ad ${index}`} className="ad-image" />
            <button className="delete-btn" onClick={() => deleteAd(ad._id)}>
              Delete
            </button>
          </div>
        ))}
      </div>

      <Footer />
    </>
  );
};

export default DeleteAd;
