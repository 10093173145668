import React, { useState, useEffect } from "react";
import "./GetAllQuiz.css"; // Add a separate CSS file for styling
import AdminDashboard from "../AdminPage/Admin";

const GetAllQuiz = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch quizzes from the API
  const fetchQuizzes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/quiz/getQuizAll`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch quizzes");
      }
      const data = await response.json();
      console.log(data);

      setQuizzes(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete quiz by ID
  const deleteQuiz = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/quiz/deleteQuiz/${id}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete quiz");
      }

      // Filter out the deleted quiz from the state
      setQuizzes(quizzes.filter((quiz) => quiz._id !== id));
    } catch (err) {
      setError(err.message);
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchQuizzes();
  }, []);

  return (
    <>
      <AdminDashboard />

      <div className="quiz-container1">
        <h2>All Quizzes</h2>

        {isLoading && <p>Loading quizzes...</p>}
        {error && <p className="error-message">{error}</p>}

        <div className="quiz-list">
          {quizzes.map((quiz) => (
            <div key={quiz._id} className="quiz-card">
              <h3>{quiz.question}</h3>
              <ul>
                {quiz.options.map((option, index) => (
                  <li
                    key={index}
                    className={quiz.correctAnswer === index ? "correct" : ""}
                  >
                    {option}
                  </li>
                ))}
              </ul>
              <p>
                <strong>Correct Answers:</strong>{" "}
                {quiz.options[quiz.correctAnswer]}
              </p>
              <button
                className="delete-btn1"
                onClick={() => deleteQuiz(quiz._id)}
              >
                Delete Quiz
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default GetAllQuiz;
