import React, { useState } from "react";
import "./CreateTyping.css";
import { enqueueSnackbar } from "notistack";
import ButtonInput from "../buttonInput/ButtonInput";
import Footer from "../footer/Footer";
import AdminDashboard from "../AdminPage/Admin";

const CreateTyping = () => {
  const [chapterName, setChapterName] = useState("");
  const [para, setPara] = useState("");
  const [isType, setIsType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!chapterName || !para || !isType) {
      enqueueSnackbar("Please fill all the fields", { variant: "error" });
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/typing/word`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chapterName: chapterName,
            para: para,
            isType: isType,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create typing data");
      }

      const data = await response.json();
      console.log("Typing data created:", data);
      enqueueSnackbar("Typing data created successfully", {
        variant: "success",
      });

      setChapterName("");
      setPara("");
      setIsType("");
    } catch (error) {
      console.error("Error creating typing data:", error);
      enqueueSnackbar("Error creating typing data", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* <Nav /> */}
      <AdminDashboard/>
      <div className="create-typing-container">
        <h1>Create Typing Data</h1>
        <form onSubmit={handleSubmit} className="create-typing-form">
          <label htmlFor="chapterName">Chapter Name</label>
          <input
            type="text"
            id="chapterName"
            value={chapterName}
            onChange={(e) => setChapterName(e.target.value)}
            placeholder="Enter Chapter Name"
            required
          />

          <label htmlFor="para">Chapter Data</label>
          <textarea
            id="para"
            value={para}
            onChange={(e) => setPara(e.target.value)}
            placeholder="Enter Chapter Data"
            rows="6"
            required
          />

          <div className="language-selection">
            <p>Select Language:</p>
            <label>
              <input
                type="radio"
                value="hindi"
                checked={isType === "hindi"}
                onChange={(e) => setIsType(e.target.value)}
              />
              <span></span>
              Hindi
            </label>
            <label>
              <input
                type="radio"
                value="english"
                checked={isType === "english"}
                onChange={(e) => setIsType(e.target.value)}
              />
              <span></span>
              English
            </label>
          </div>

          <ButtonInput
            isLoading={isLoading}
            title="Create Typing Data"
            className="Login-btn"
          />
        </form>
      </div>
      <Footer />
    </>
  );
};

export default CreateTyping;
