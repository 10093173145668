

import React from "react";
import "./AllUgCourses.css";
import Nav from "../Navpage/Nav";
import Footer from "../footer/Footer";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const AllUgCourses = () => {
  const location = useLocation();
  const imgpath = location.state;
  console.log(imgpath);

  const data = useSelector((elem) => elem.currentUser.user);
  const name = data[0]?.username;

  const courseSelector = useSelector((res) => res.course.valuee);

  const filterData = courseSelector.filter(
    (e) => e.coursetype === imgpath.type
  );
  console.log(filterData);

  return (
    <>
      <div id="ugCourses-mainpage">
        <Nav name={name} />
        <div id="mainImagediv">
          <img src={imgpath.img} alt="Imagee" />
          <h4> {imgpath.describe} </h4>
        </div>
        <div id="mainCards-section">
          <div className="flex-container">
            {filterData.map((e, i) => {
              return (
                <div className="card" key={i}>
                  <img src={e.courseimg} alt={e.coursename} className="card-img" />
                  <div className="card-content">
                    <h2>{e.coursename}</h2>

                    <p><strong>Duration:</strong> {e.courseduration}</p>
                    <p><strong>Eligibility:</strong> {e.courseeli}</p>
                  </div>
                </div>
              );
            })}

          </div>

        </div>
        <Footer />
      </div>
    </>
  );
};

export default AllUgCourses;
