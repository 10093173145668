import React from "react";
import Nav from "../Navpage/Nav";
import Footer from "../footer/Footer";
import "./AboutUs.css";
import Aboutpagecarousel from "../Carousel/Aboutpagecarousel";
import { useSelector } from "react-redux";



const AboutUs = () => {
  const data = useSelector((elem) => elem.currentUser.user);
  const name = data[0]?.user.username;


  return (
    <div>
      <Nav name={name || "USER"} />
      <div id="About-main-page1">
        <div id="About-submain-page">
          <div id="About-left-submain">
            <div id="About-left-description">
              <div id="description-heading">
                <h1 className="Aboutus-main-heading">About Us</h1>
              </div>
              <div id="description-subheading">
                <h4 className="Aboutus-main-subheading">
                  We are committed to providing top-notch education, blending
                  traditional values with modern learning techniques. Our
                  mission is to empower students with the skills and knowledge
                  they need to succeed in their careers and personal lives.
                  Through dedicated teaching and a supportive environment, we
                  strive to shape the leaders of tomorrow
                </h4>
              </div>
              <div id="description-button">
                <label id="para"><button className="description-btn">Learn More</button></label>
              </div>
            </div>
          </div>
          <div id="About-right-submain" style={{ width: '100%', height: 'auto' }}>
            <iframe
              id="inlineFrameHitech"
              title="map"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d458.3333389783794!2d77.4221535!3d23.2296649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c42f53c880af7%3A0x2f11fd292161a05d!2sHitech%20Group%20Of%20Institutions!5e0!3m2!1sen!2sin!4v1694956250455!5m2!1sen!2sin"
            ></iframe>
          </div>


        </div>


        <div id="About-page2">
          <div id="About-page2-img">
            <Aboutpagecarousel />
          </div>
          <div id="About-page2-description">
            <div id="About-page2-description-center-div">
              <h1 className="About-page2-heading">Get to Know Us Better</h1>
              <p className="About-us-paragraph" id="para">
                Since 2005, Hitech Group Of Institutions in Shivaji Nagar,
                Bhopal has been offering professional training to students. It
                specialises and is well-known for training students as well as
                working professionals in accounting, web designing, programming
                languages, hardware and networking. It is run and managed by a
                seasoned professionals who leads a team of educators and
                trainers having relevant domain expertise. At this institution,
                one can get trained in the subject of their choice by opting
                from a wide range of courses. These easy-to-follow courses are
                primarily aimed at students, working professionals as well as IT
                professionals who want to enhance their knowledge and further
                their career prospects. Located Near Congress Bhawan,Near Maa
                Hinglaj Darbar, you can find this institution with relative ease
                at BDA 96 C in Shivaji Nagar. Undoubtedly it is one of the best
                computer training institutes in Shivaji Nagar, Bhopal Hitech
                Group Of Institutions in Shivaji Nagar offers short-term courses
                and certificate courses. Inclusive of comprehensive learning,
                the long-term programmes feature subjects such as web
                development, financial accountancy, computer application and
                programming, information technology, multimedia and
                web-designing. Some of the short-term courses cover topics like
                Windows XP, 7, 8, 10, Vista, MS Office, DTP (Desk Top
                Publishing), Web Designing, Multimedia, Tally ERP 9, C, C++ ,
                and Visual Basic. Walk into this centre all through the week
                between 08:00 – 20:00. Pay in Cash, Master Card, Visa Card,
                Debit Cards, Cheques, Credit Card. Please scroll to the top for
                the address and contact details of Hitech Group Of Institutions
                at Shivaji Nagar, Bhopal Hitech Group Of Institutions in Bhopal
                is one of the leading businesses in the Institutes. Also known
                for Computer Training Institutes, Tally Training Institutes,
                AUTOCAD Training Institutes, Institutes, Shorthand Training,
                Institutes For Distance Education, Computer Training Institutes
                For PGDCA, Typing Classes and much more. Find Address, Contact
                Number, Reviews & Ratings, Photos, Maps of Hitech Group Of
                Institutions, Bhopal.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
