import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="30"
      fill="none"
      viewBox="0 0 36 30"
    >
      <path
        fill="#E0E0E0"
        d="M24.5 29.8l11-27.5L29.9 0 16.6 26.7l7.9 3.1zm-16.6 0l11-27.5L13.3 0 0 26.7l7.9 3.1z"
      ></path>
    </svg>
  );
}

export default Icon;
