import React, { useState, useEffect } from "react";
import "./StudentQuiz.css"; // Add your CSS file for styling

const StudentQuiz = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch quizzes from the API
  const fetchQuizzes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/quiz/getQuizAll`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch quizzes");
      }
      const data = await response.json();
      setQuizzes(data.reverse());
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchQuizzes();
  }, []);

  const currentQuiz = quizzes[currentQuizIndex];

  const handleOptionSelect = (index) => {
    setSelectedOption(index);
    setShowResult(true);
  };

  const handleNext = () => {
    if (currentQuizIndex + 1 < quizzes.length) {
      setCurrentQuizIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentQuizIndex(0); // Reset to first quiz if at the end
    }
    setSelectedOption(null);
    setShowResult(false);
  };

  return (
    <div className="quiz-container">
      <h3> CPCT MCQ  </h3>
      {isLoading && <p>Loading quizzes...</p>}
      {error && <p className="error-message">{error}</p>}

      {quizzes.length > 0 && (
        <div className="quiz-card">
          <h3>{currentQuiz.question}</h3>
          <ul className="options-list">
            {currentQuiz.options.map((option, index) => (
              <li
                key={index}
                className={`option ${
                  selectedOption !== null &&
                  (index === selectedOption
                    ? selectedOption === currentQuiz.correctAnswer
                      ? "correct"
                      : "incorrect"
                    : index === currentQuiz.correctAnswer
                    ? "correct"
                    : "")
                }`}
                onClick={() => handleOptionSelect(index)}
              >
                {option}
              </li>
            ))}
          </ul>
          {showResult && (
            <div className="result-message">
              {selectedOption === currentQuiz.correctAnswer
                ? "Correct answer!"
                : "Wrong answer! The correct answer is: " +
                  currentQuiz.options[currentQuiz.correctAnswer]}
            </div>
          )}
          <button
            className="next-button"
            onClick={handleNext}
            disabled={selectedOption === null}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default StudentQuiz;
