import React from "react";
import { Link, Outlet } from "react-router-dom";
import "./Admin.css";
import Nav from "../Navpage/Nav";
import { useSelector } from "react-redux";

const AdminDashboard = () => {
  const addminDashboard = [
    {
      route: "/createcourse",
      routetitle: "Create Course",
    },
    {
      route: "/getAllcourse",
      routetitle: "All Course",
    },
    {
      route: "/generatecertificate",
      routetitle: "Generate Certificate",
    },
    {
      route: "/getAllCertificate",
      routetitle: "Get All Certificate",
    },
    {
      route: "/createTyping",
      routetitle: "Create Typing Data",
    },
    {
      route: "/getAlltyping",
      routetitle: "Get All Data",
    },
    {
      route: "/advertisement",
      routetitle: "Add your AD",
    },

    {
      route: "/deleteAd",
      routetitle: "Delete your AD",
    },
    {
      route: "/makeAdmin",
      routetitle: "Make Admin",
    },
    {
      route: "/createQuiz",
      routetitle: "Add Quiz Data",
    },
    {
      route: "/getAllQuix",
      routetitle: "Get All Quiz Data",
    },
    {
      route: "/createNotification",
      routetitle: "Create Notification",
    },
  ];

  const data = useSelector((elem) => elem.currentUser.user);
  const name = data[0]?.username;
  return (
    <>
      <Nav name={name || "USER"} />
      <div className="admin-dashboard">
        <div className="sidebar">
          <h2>Admin Dashboard</h2>
          <ul>
            {addminDashboard.map((e) => {
              return (
                <li>
                  <Link to={e.route}> {e.routetitle} </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
