import React, { useState, useEffect } from "react";
import AdminDashboard from "../AdminPage/Admin";
import { enqueueSnackbar } from "notistack";
import "./CreateNotification.css";

const CreateNotification = () => {
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("job"); // Default type
  const [examLink, setExamLink] = useState(""); // State for examLink
  const [notifications, setNotifications] = useState([]); // State for fetched notifications

  // Fetch notifications from the API
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/notification/getNotification`
        );
        const data = await response.json();
        setNotifications(data); // Update state with fetched data
      } catch (error) {
        enqueueSnackbar("Failed to fetch notifications", { variant: "error" });
      }
    };

    fetchNotifications();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const notificationData = {
      name: notificationText,
      isType: notificationType,
      examLink: examLink || undefined, // Add examLink regardless of type; will be undefined if not provided
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/notification/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(notificationData),
        }
      );

      if (response.ok) {
        enqueueSnackbar("Notification created successfully", {
          variant: "success",
        });
        setNotificationText(""); // Clear input fields after submission
        setExamLink("");
        // Refetch the updated notifications after adding a new one
        const updatedNotifications = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/notification/getNotification`
        );
        const updatedData = await updatedNotifications.json();
        setNotifications(updatedData); // Update the notifications list
      } else {
        throw new Error("Failed to create notification");
      }
    } catch (error) {
      enqueueSnackbar(`Error: ${error.message}`, { variant: "error" });
    }
  };

  // Function to delete notification
  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/notification/deletenotification/${id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        enqueueSnackbar("Notification deleted successfully", {
          variant: "success",
        });
        // Remove the deleted notification from the local state
        setNotifications(
          notifications.filter((notification) => notification._id !== id)
        );
      } else {
        throw new Error("Failed to delete notification");
      }
    } catch (error) {
      enqueueSnackbar(`Error: ${error.message}`, { variant: "error" });
    }
  };

  return (
    <>
      <AdminDashboard />
      <div className="notification-wrapper">
        <h2>Create Notification</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Notification Text:
              <input
                type="text"
                value={notificationText}
                onChange={(e) => setNotificationText(e.target.value)}
                required
              />
            </label>
          </div>

          <div>
            <label>
              <input
                type="radio"
                value="job"
                checked={notificationType === "job"}
                onChange={() => setNotificationType("job")}
              />
              Job
            </label>
            <label>
              <input
                type="radio"
                value="notification"
                checked={notificationType === "notification"}
                onChange={() => setNotificationType("notification")}
              />
              Notification
            </label>
          </div>

          {/* Always show examLink input */}
          <div>
            <label>
              Link (Optional):
              <input
                type="url"
                value={examLink}
                onChange={(e) => setExamLink(e.target.value)}
                placeholder="Enter exam link"
              />
            </label>
          </div>

          <button type="submit">Submit</button>
        </form>

        {/* Display fetched notifications */}
        <div className="data-list">
          <h3>Existing Notifications and Jobs</h3>
          {notifications.length > 0 ? (
            notifications.map((item, index) => (
              <div key={index} className={`data-tile ${item.isType}`}>
                <h4>
                  {item.isType === "job" ? "Job Notification" : "Notification"}
                </h4>
                <p>{item.name}</p>
                {item.isType === "notification" && item.examLink && (
                  <a
                    href={item.examLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Exam Link
                  </a>
                )}
                {/* Delete button */}
                <button className="" onClick={() => handleDelete(item._id)}>
                  Delete
                </button>
              </div>
            ))
          ) : (
            <p>No notifications available.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateNotification;
