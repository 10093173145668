import React from "react";
import Footer from "../footer/Footer";
import Nav from "../Navpage/Nav";
import "./Course.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

const Course = () => {
  const data = useSelector((elem) => elem.currentUser.user);
  const name = data[0]?.user.username;

  const courseSelector = useSelector((res) => res.course.valuee);
  const navigate = useNavigate();

  const coursebyType = courseSelector.reduce((acc, current) => {
    if (!acc.some((item) => item.coursetype === current.coursetype)) {
      acc.push(current);
    }
    return acc;
  }, []);

  return (
    <>
      <Nav name={name || "USER"} />

      <div id="course-main-page">
        <div id="course-submain-page">
          <div id="course-heading">
            <h1 className="course-h1-heading">All Courses</h1>
            <button className="course-heading-btn">All Categories</button>
          </div>

          <div className="course-cards-container">

            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              navigation={true}
              pagination={{ clickable: true }}
              loop={true}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              modules={[Navigation, Pagination, Autoplay]}
              breakpoints={{
                1024: { slidesPerView: 3 },
                768: { slidesPerView: 2 },
                640: { slidesPerView: 1 },
              }}
              className="course-swiper"
            >
              {coursebyType.map((e, i) => (
                <SwiperSlide key={i}>
                  <div className="course-card">
                    <div className="course-card-img-section">
                      <img
                        className="course-card-img"
                        src={e.courseimg}
                        alt="course-img"
                      />
                    </div>
                    <div className="course-card-content">
                      <h2 className="course-name">{e.coursename}</h2>
                      <p className="course-description">{e.coursedes}</p>
                      <div className="course-details">
                        <h5>Duration: {e.courseduration}</h5>
                        <h5>Eligibility: {e.courseeli}</h5>
                      </div>
                      <button
                        className="course-btn"
                        onClick={() => {
                          navigate("/ugcourses", {
                            state: { img: e.courseimg, type: e.coursetype, describe: e.coursedes },
                          });
                        }}
                      >
                        View Course
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Course;
